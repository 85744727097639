import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav white-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src="assets/img/logo-white-1x-syp.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logo-white-1x-syp.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <Link
                    to="/"
                    className="nav-link"
                    href="#"
                  >
                    Home
                  </Link>
                  {/* <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownHome"
                  >
                    <Link to="/" className="dropdown-item">
                      Demo Template 1
                    </Link>
                    <Link to="/home-two" className="dropdown-item">
                      Demo Template 2
                    </Link>
                    <Link to="/home-three" className="dropdown-item">
                      Demo Template 3
                    </Link>
                    <Link to="/home-four" className="dropdown-item">
                      Demo Template 4
                    </Link>
                    <Link to="/home-five" className="dropdown-item">
                      Demo Template 5
                    </Link>
                    <Link to="/home-six" className="dropdown-item">
                      Demo Template 6
                    </Link>
                    <Link to="/home-seven" className="dropdown-item">
                      Demo Template 7
                    </Link>
                    <Link to="/home-eight" className="dropdown-item">
                      Demo Template 8
                      <span className="badge badge-warning">New</span>
                    </Link>
                  </div> */}
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='#about'>
                    Nosotros
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='/our-team'>
                    Nuestro Equipo
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#contact">
                    Contacto
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
