import React from "react";

export default function Team({ hasTitle, bgColor }) {

  // esto es nuestros artistas 

  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                {hasTitle ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Lovely Team
                  </span>
                ) : (
                  ""
                )}
                <h2>Artistas</h2>
                <p className="lead">
                  Algunos de nuestros artistas mas reconocidos, los que gracias a nosotros pudieron llegar a la escena nacional e internacional en la musica.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/artist-1b.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Tiago PZK</h5>
                  <h6>Canta-Autor</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.youtube.com/channel/UCMebXsaxWdvBzBNwus7TmxA">
                        <span className="ti-youtube"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://open.spotify.com/artist/5Y3MV9DZ0d87NnVm56qSY1?si=pKapJnj6Q6qH8_2yIuUeuQ">
                        <span className="ti-music"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://open.spotify.com/playlist/6KHcPfIzmrC6L2Qt72B2dt?si=d1e9cc9441514c7a">
                        <span className="ti-music-alt"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/artist-2.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Emkier</h5>
                  <h6>Canta/Autor</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://open.spotify.com/artist/5yEDqRhb3ABb25Of0IYpcn?si=tMYDvyGkQRmWA1cYWhpjmw">
                        <span className="ti-music"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/artist-3.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Pau the Kid</h5>
                  <h6>Cantante</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.youtube.com/c/pauthekid">
                        <span className="ti-youtube"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://open.spotify.com/artist/5pXMTmq8ePLmB98pNuEnyJ?si=WsKfXe2DST6oKAls-3hK6Q">
                        <span className="ti-music"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/artist-5.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Fms Argentina 2019</h5>
                  <h6>Comercializadora de RAP</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://open.spotify.com/playlist/4BbarbaPZBRpq9TRJgh1Qq?si=83999894bd044baa">
                        <span className="ti-music"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
