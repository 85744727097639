import React from "react";
import Contact from "../../components/contact/Contact";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Promo from "../../components/promo/Promo";
import Team from "../../components/team/Team";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroSeven from "./HeroSeven";

export default function HomeSeven() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroSeven />
      <Promo />
      <FeatureImgTwo />
      {/* <FeatureImgThree />
      <Price hasBg />
      <TestimonialTwo />
      <Screenshot hasBg /> */}
      {/* <Cta />
      <LatestNewsOne bgColor="gray-light-bg" light /> */}
      <Team />
      <Contact bgColor="gray-light-bg" />
      <BrandCarousel />
      {/* <Subsribe /> */}
      <Footer space />
    </Layout>
  );
}
