import React from "react";

export default function Teams() {
  return (
    <>
      <section className="team-member-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-4">
                <h2>Conoce a nuestros fundadores</h2>
                <p className="lead">
                  Somos apasionados por la musica y la ingenieria, 
                  combinamos lo mejor de dos mundos para ofrecerte soluciones integrales para que puedas crecer como artista.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
                <div className="team-image">
                  <img
                    src="assets/img/damian.png"
                    alt="Team Members"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Damian Frieri</h5>
                  <h6>CEO</h6>
                  {/* <ul className="list-inline team-social social-icon my-4 text-white">
                    <li className="list-inline-item">
                      <Link to="#/">
                        <span className="ti-facebook"></span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#/">
                        <span className="ti-twitter"></span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#/">
                        <span className="ti-github"></span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#/">
                        <span className="ti-dribbble"></span>
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
                <div className="team-image">
                  <img
                    src="assets/img/adrian.png"
                    alt="Team Members"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Adrian Bruni</h5>
                  <h6>CTO</h6>
                  </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
                <div className="team-image">
                  <img
                    src="assets/img/gaston.png"
                    alt="Team Members"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                  <h5 className="mb-0">Gaston massuco</h5>
                  <h6>MANAGER</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
